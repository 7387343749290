/* eslint @typescript-eslint/no-unsafe-argument: 0 */
/* eslint @typescript-eslint/no-unsafe-assignment: 0 */
/* eslint @typescript-eslint/no-unsafe-call: 0 */
/* eslint @typescript-eslint/no-unsafe-return: 0 */

import { zodResolver } from "@hookform/resolvers/zod"
import { useId, useState } from "react"
import {
    FieldArray,
    FieldArrayPath,
    FieldPath,
    FieldPathValue,
    FieldValues,
    FormProvider,
    GlobalError,
    Path,
    PathValue,
    SubmitHandler,
    UseFormProps,
    UseFormRegister,
    UseFormReturn,
    useForm,
    useFormContext,
} from "react-hook-form"
import { z } from "zod"
import { useToast } from "~/app/_hooks/useToast"
import deepGet from "~/shared/deepGet"

export type UseZodFormRegister<TInput extends FieldValues> =
    UseFormRegister<TInput>

type PayloadFieldArrayFn<
    TFieldValues extends FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues>,
> = (
    value:
        | FieldArray<TFieldValues, TFieldArrayName>
        | FieldArray<TFieldValues, TFieldArrayName>[],
) => void

type IndexFieldArrayFn = (index: number) => void

type ValuesFieldArrayFn<
    TFieldValues extends FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues>,
> = () => PathValue<TFieldValues, TFieldArrayName>

type UseFormFields<
    TFieldValues extends FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues>,
> = {
    append: PayloadFieldArrayFn<TFieldValues, TFieldArrayName>
    remove: IndexFieldArrayFn
    values: ValuesFieldArrayFn<TFieldValues, TFieldArrayName>
}

type FieldsFn<TFieldValues extends FieldValues> = <
    TFieldArrayName extends FieldArrayPath<TFieldValues>,
>(
    path: TFieldArrayName,
) => UseFormFields<TFieldValues, TFieldArrayName>

export type UseZodForm<TFieldValues extends FieldValues> =
    UseFormReturn<TFieldValues> & {
        id: string
        fields: FieldsFn<TFieldValues>
        error: <TPath extends FieldPath<TFieldValues>>(
            path: TPath,
        ) => GlobalError
    }

export function useZodForm<TSchema extends z.ZodType>(
    props: Omit<UseFormProps<TSchema["_input"]>, "resolver"> & {
        schema: TSchema
    },
) {
    const form = useForm<TSchema["_input"]>({
        ...props,
        resolver: zodResolver(props.schema, undefined, {
            // This makes it so we can use `.transform()`s on the schema
            // without same transform getting applied
            // again when it reaches the server
            raw: true,
        }),
    }) as UseZodForm<TSchema["_input"]>

    form.id = useId()
    const myFields = (path: any) => ({
        append: (value: any) => {
            const values = form.getValues(path)
            form.setValue(path, [...values, value] as any)
        },
        remove: (index: number) => {
            const values = form.getValues(path)
            values.splice(index, 1)
            form.setValue(path, values)
        },
        values: () => form.getValues(path),
    })
    form.fields = myFields as unknown as FieldsFn<TSchema["_input"]>
    form.error = (path: any) => {
        return deepGet(form.formState.errors, path) ?? {}
    }

    return form
}

export type AnyZodForm = UseZodForm<any>

export function ZodForm<TInput extends FieldValues>(
    props: Omit<React.ComponentProps<"form">, "onSubmit" | "id"> & {
        handleSubmit: SubmitHandler<TInput>
        form: UseZodForm<TInput>
    },
) {
    const { handleSubmit, form, ...passThrough }: typeof props = props
    const toast = useToast()
    return (
        <FormProvider {...form}>
            <form
                {...passThrough}
                id={form.id}
                onSubmit={(event) => {
                    // eslint-disable-next-line @typescript-eslint/no-floating-promises
                    form.handleSubmit(async (values) => {
                        try {
                            await handleSubmit(values)
                        } catch (cause: any) {
                            toast({
                                type: "error",
                                message: "Something went wrong.",
                            })
                            /* form.setError("root.server", {
                                message:
                                    (cause as Error)?.message ??
                                    "Unknown error",
                                type: "server",
                            }) */
                        }
                    })(event)
                }}
            />
        </FormProvider>
    )
}

// Only the used areas of the form are supported by this.
// Needs to be extended if any advanced scenarios are needed.
export function useSubForm<
    TFieldValues extends FieldValues,
    TBasePath extends FieldPath<TFieldValues>,
>(
    form: UseZodForm<TFieldValues>,
    basePath: TBasePath,
): UseZodForm<FieldPathValue<TFieldValues, TBasePath>> {
    const [subForm] = useState(() => {
        return {
            id: form.id,
            getValues: (
                path?: Path<FieldPathValue<TFieldValues, TBasePath>>,
            ) =>
                path
                    ? form.getValues(`${basePath}.${path}` as any)
                    : form.getValues(basePath),
            watch: (path: any) =>
                path
                    ? form.watch(`${basePath}.${path}` as any)
                    : form.watch(basePath),
            formState: form.formState,
            setValue: (path: any, value: any, options: any) =>
                form.setValue(`${basePath}.${path}` as any, value, options),
            register: (path: any) =>
                form.register(`${basePath}.${path}` as any),
            fields: (path: any) => ({
                append: (value: any) =>
                    form.fields(`${basePath}.${path}` as any).append(value),
                remove: (index: number) =>
                    form.fields(`${basePath}.${path}` as any).remove(index),
                values: () =>
                    form.fields(`${basePath}.${path}` as any).values(),
            }),
            error: (path: any) =>
                deepGet(form.formState.errors, `${basePath}.${path}`) ?? {},
            control: form.control as any,
        }
    })

    return subForm as unknown as UseZodForm<
        FieldPathValue<TFieldValues, TBasePath>
    >
}

export function SubmitButton(
    props: Omit<React.ComponentProps<"button">, "type" | "form"> & {
        form?: AnyZodForm
    },
) {
    const context = useFormContext()

    const form = props.form ?? context
    if (!form) {
        throw new Error(
            "SubmitButton must be used within a Form or have a form prop",
        )
    }
    const { formState } = form

    return (
        <button
            {...props}
            form={props.form?.id}
            type="submit"
            disabled={formState.isSubmitting}
        >
            {formState.isSubmitting ? "Loading" : props.children}
        </button>
    )
}
