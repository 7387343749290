"use client"

import { signIn } from "next-auth/react"
import Link from "next/link"
import CenteredFormContainer from "~/app/_components/CenteredFormContainer"
import userSchemas from "~/shared/userSchemas"
import FormInput from "../../_components/form/FormInput"
import FormSubmit from "../../_components/form/FormSubmit"
import { ZodForm, useZodForm } from "../../_components/form/form"
import useSignInRedirect from "../useSignInRedirect"

export default function SignIn() {
    const form = useZodForm({
        schema: userSchemas.signIn,
    })

    const [pendingRedirect, setPendingRedirect] = useSignInRedirect()

    return (
        <CenteredFormContainer title="Sign in to your account">
            <ZodForm
                form={form}
                handleSubmit={async (e) => {
                    const response = await signIn("credentials", {
                        ...e,
                        redirect: false,
                    })

                    if (response?.ok) {
                        setPendingRedirect(true)
                    } else {
                        form.setError("password", {
                            type: "custom",
                            message:
                                "Unable to sign-in. Double-check your credentials.",
                        })
                    }
                }}
                className="space-y-6"
            >
                <FormInput
                    label="Email address"
                    type="email"
                    autoComplete="email"
                    form={form}
                    path="email"
                />
                <FormInput
                    label="Password"
                    type="password"
                    form={form}
                    path="password"
                />
                <FormSubmit form={form} disabled={pendingRedirect}>
                    Log In
                </FormSubmit>
            </ZodForm>
            <div className="py-4">
                Forgot your password?{" "}
                <Link
                    href="/reset-password"
                    className="rounded-full text-indigo-600"
                >
                    Reset Password
                </Link>
            </div>
            <div>
                Don't have an account?{" "}
                <Link href="/register" className="rounded-full text-indigo-600">
                    Register
                </Link>
            </div>
        </CenteredFormContainer>
    )
}
