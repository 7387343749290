import { z } from "zod"

const adminEmail = z
    .string()
    .email()
    .endsWith(
        "@accessly.com.au",
        "Registration only permitted to administrators",
    )

const passwordStringSchema = z
    .string({
        invalid_type_error:
            "The password length must be between 4 and 20 characters",
    })
    .min(4)
    .max(20)

const password = z.object({
    password: passwordStringSchema,
})

const email = z.object({
    email: z.string().email(),
})

const signIn = z.object({
    email: z.string().email(),
    password: passwordStringSchema,
})

const register = z.object({
    name: z.string().min(1).max(100),
    email: adminEmail,
    password: passwordStringSchema,
})

const changePassword = z
    .object({
        password: passwordStringSchema,
        newPassword: passwordStringSchema,
        confirmNewPassword: z.string().min(1, "Please Confirm Password"),
    })
    .refine((data) => data.newPassword === data.confirmNewPassword, {
        message: "Passwords don't match",
        path: ["confirmNewPassword"],
    })

const userSchemas = {
    password,
    email,
    signIn,
    register,
    changePassword,
}

export default userSchemas
