"use client"

import { HTMLInputTypeAttribute } from "react"
import { FieldValues, Path } from "react-hook-form"
import { UseZodForm } from "./form"

type Props<T extends FieldValues> = {
    id?: string
    label: string
    form: UseZodForm<T>
    path: Path<T>
    placeholder?: string
    type?: HTMLInputTypeAttribute
    autoComplete?: string
}

export default function FormInput<T extends FieldValues>(props: Props<T>) {
    const error = props.form.error(props.path)

    return (
        <div>
            <label
                htmlFor={props.id ?? props.path}
                className="block text-sm leading-6 text-gray-900"
            >
                {props.label}
            </label>
            <div className="mt-2">
                <input
                    id={props.id ?? props.path}
                    type={props.type ?? "text"}
                    placeholder={props.placeholder ?? ""}
                    autoComplete={props.autoComplete}
                    disabled={props.form.formState.isSubmitting}
                    {...props.form.register(props.path)}
                    className="block w-full rounded-lg border-0 px-4 py-2.5 text-gray-900 shadow-sm ring-1
                        ring-inset ring-gray-300 placeholder:text-gray-400
                        focus:shadow-[0px_0px_0px_4px_#F4EBFF,0px_1px_2px_0px_rgba(16,24,40,0.05)]
                        focus:ring-1 focus:ring-inset focus:ring-violet-500 sm:text-sm sm:leading-6"
                />
                {error && (
                    <p className="mt-1 text-sm text-red-600">
                        {error?.message}
                    </p>
                )}
            </div>
        </div>
    )
}
